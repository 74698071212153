<template>
	<div>

		<!-- mask to close field if user click outside the field -->
		<div 
		class="inputmask" 
		v-if="isOptionsListOpen"
		@click="closeWithoutSelection"
		></div>

		<div class="selectfield">
			<label :for="fieldname"><span v-html="label"></span><span v-if="isRequired" class="req">&nbsp;*</span></label>

			<div class="dropdown" :class="{'open': isOptionsListOpen}">

				<input 
				type="text"
				autocomplete="off"
				:id="fieldname"
				v-model="textInField"
				@input="userChangedInput"
				:placeholder = "placeholder"
				:class="{'hasSuccess': isInSuccess, 'hasError': isInError, 'isLoading': isLoading}"
				@keyup.prevent.down="keyPressedDown"
				@keyup.prevent.up = "keyPressedUp"
				@keyup.prevent.enter = "keyPressedEnter"
				:disabled="isDisabled"
				>

				<!-- Toggler -->
				<div class="toggle" @click="toggleListOpeness()">
					<span class="arrow-up" v-show="isOptionsListOpen">▲</span>
					<span class="arrow-down" v-show="!isOptionsListOpen">▼</span>
				</div>

				<!-- Options list -->
				<ul
				v-show="isOptionsListOpen"
				class="options-list">
				<li 
				v-for="(option, index) in optionsList"
				:key="index"
				@click="selectThisOption(option, index)"
				:class="{'active': currentActiveOption === index}"
				>{{option.description}}</li>
				<li v-show="noResult" @click="closeWithoutSelection">{{$t('inputLabel.noResult')}}</li>
			</ul>

		</div>
	</div>

	<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
	<div class="helper"><span v-html="helper"></span></div>

</div>
</template>

<!-- ================================================================================== -->

<script>
	import FormFields from "../mixins/FormFields";
	import customLog from "../mixins/CustomLog";

	export default {
		name:"SelectFree",
		mixins: [
			customLog,
			FormFields,
			],
		props: {
			label : {
				type: String,
				required: true, /* false in meta only */
			},
			dataArray : { 
				/* only for free select field */
				type: Array,
				required: true,
			},
			caractersBeforeOpenList : {
				type: Number,
				required: false,
			default: 3
			},
			autocomplete: {
				type: Boolean,
				required: true,
			},
			isDisabled: {
				type: Boolean,
				required: false,
			default: false,
			}
		},
		data(){
			return {
				isOptionsListOpen: false,
				optionsList: [],
				displayedInTheField: null,
				noResult: false,
				currentActiveOption: null,
			}
		},
		computed: {
			textInField: {
				get(){
					return this.displayedInTheField;
				},
				set(newVal){
					this.displayedInTheField = newVal;
				}
			}
		},

		methods: {

			keyPressedDown(){
				if(this.isOptionsListOpen && this.currentActiveOption < this.optionsList.length -1){
					this.downOneItem();
				}
				if(!this.isOptionsListOpen){
					this.makeNewOptionsList(false);
					this.isOptionsListOpen = true;
				}
			},

			keyPressedUp(){
				if(this.isOptionsListOpen && this.currentActiveOption > 0){
					this.upOneItem();
				}else{
					this.closeOptionsList();
					this.currentActiveOption = null;
				}
			},

			keyPressedEnter(){
				if(this.isOptionsListOpen){
					this.log("Enter when list is open", 'low');
					this.selectThisOption(this.optionsList[this.currentActiveOption], this.currentActiveOption);
				}
			},

			upOneItem(){
				this.currentActiveOption -= 1;
			},

			downOneItem(){
				this.currentActiveOption += 1;
			},

			selectThisOption(option, index){
				this.log(`User choose item ${option.id} - ${option.description}`, 'low');
				this.displayedInTheField = option.description;
				this.currentActiveOption = index;
				this.closeOptionsList(option.id);
			},

			closeWithoutSelection(){
				this.log("Closing the optionList without selection", 'low');
				this.textInField = "";
				this.closeOptionsList();
				this.currentActiveOption = null; /* maybe could be inchanged instead? */
				this.runValidationProcess();
				this.$emit('update:modelValue', null);
			},

			toggleListOpeness(){
				this.isOptionsListOpen = !this.isOptionsListOpen;
				if(this.isOptionsListOpen){
					this.displayedInTheField = "";
					this.makeNewOptionsList(this.autocomplete);
				}
			},

			emptyAndCloseList(){
				this.optionsList = [];
				this.closeOptionsList();
			},

			userChangedInput(){
				/* Refresh list of options */
				/* User left no caracters => options list is empty */
				if(this.displayedInTheField.length === 0){
					this.emptyAndCloseList();
				}
				/* User type some caracters => options list matching strings */
				if(this.displayedInTheField.length >= this.caractersBeforeOpenList){
					this.makeNewOptionsList(this.autocomplete);
					if(!this.isOptionsListOpen){ this.openOptionsList(); }
				}
				/* Display "no result" instead of nothing */
				this.noResult = (this.displayedInTheField.length >= this.caractersBeforeOpenList && this.optionsList.length < 1);
			},

			makeNewOptionsList(bool){
				/* If autocomplete is true, it's a partial list */
				if(bool){
					let userInput = this.displayedInTheField.toLowerCase();
					this.optionsList = this.dataArray.filter(option => option.description.toLowerCase().includes(userInput));
				}
				/* If autocomplete is false, it's a full list */
				if(!bool){
					this.optionsList = this.dataArray;
				}
			},

			openOptionsList(){
				/* User open the option list */
				this.log("Opening option list", 'low');
				/* remove previous options */
				this.optionsList = [];
				/* recreate new options list */
				this.makeNewOptionsList(this.autocomplete);
				/* DOM open the list */
				this.isOptionsListOpen = true;
			},

			closeOptionsList(id){
				/* User leave the option list */
				this.noResult = false;
				this.log("Closing option list", 'low');
				this.isOptionsListOpen = false;
				this.runValidationProcess(id);
			},

			sendDataToForm(id){
				this.log(`SelectFree Field transmitting id ${id} to parent`, 'low');
				this.$emit('update:modelValue', id);
			},

			sendValidityOfTheFieldToTheForm(bool){
				this.$emit('runCheck', {fieldname: this.fieldname, valid: bool});
			},

			whatToDoInCaseFieldIsValidated(id){
				this.log("Field content approved", 'success');
				this.sendDataToForm(id);
				this.giveSuccess();
				this.sendValidityOfTheFieldToTheForm(true);
			},

			whatToDoInCaseFieldIsNotValidated(){
				this.log("Field content not approved", 'alert');
				this.errorMessage = this.$t('inputsTexts.dropdown.missingData');
				this.giveError();
				this.sendValidityOfTheFieldToTheForm(false);
			},

			runValidationProcess(id){
				this.removeSuccessOrError();
				/* If and answer is required, the field cannot be empty */
				if(this.isRequired && this.displayedInTheField.length > 0 || !this.isRequired && this.displayedInTheField.length > 0){
					this.whatToDoInCaseFieldIsValidated(id);
				}
				if(this.isRequired && !this.displayedInTheField.length > 0){
					this.whatToDoInCaseFieldIsNotValidated(id);
				}

				/* TODO User cannot easily place a choice that don't exist but I should check nevertheless is the answer is in the given options list. If autocomplete start at 3, I can type "xx" and it stays there. */
			}

		},

		watch: {
			/* Form will send a diffrent list in case of local change. Maybe I have to check here */
			/* TODO To be TESTED */
		},

		mounted(){
			/* If a value exist in the form (reparticipation), it must be loaded. */
			/* Could be === 0 witch is a falsy value => don't use !!this.modelValue */
			if(this.modelValue !== null && this.modelValue !== ''){ 
				this.log("There is a data in the form before user intervene (reparticipation).");
				let selectedOption = this.dataArray.filter(option => option.id === parseInt(this.modelValue));
				if(selectedOption[0]){
					this.displayedInTheField = selectedOption[0]?.description;
					this.runValidationProcess(selectedOption[0]?.id);
				}
				this.$emit('update:modelValue', parseInt(this.modelValue)); // parseInt the value received from the API, just in case
			}
		},
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
	.selectfield {
		position: relative;
		z-index:20;
	}
	.inputmask {
		position: fixed; 
		top:0; bottom:0; right:0; left:0; 
		z-index:10;
	}
	.dropdown {
		display: inline-block;
		position: relative;
		z-index:60;
	}
	.toggle {
		position: absolute;
		top:50%;
		transform:translateY(-60%);
		right:1.25em;
		cursor: pointer;
	}
	.arrow-up {}
	.arrow-down {}
	.options-list {
		background-color: rgba(255, 255, 255, 0.95);
		border: 1px solid #ddd;
		list-style: none;
		display: block;
		margin: 0;
		padding: 0.5em;
		width: 100%;
		overflow: hidden;
		overflow-y:auto;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 21;
		max-height:15em;
	}
	.dropdown .options-list {
		display: none;
	}

	.dropdown.open .options-list {
		display: block;
	}

	.options-list li {
		cursor: pointer;
		padding:0.25em;
	}

	.options-list li:hover {
		background-color: #fc16;
	}

	li.active {
		background: #fc0;
	}

</style>
