<template>
    <div id="productImages">

        <img src="~@/assets/img/balloon_left.png"/>
        <img src="~@/assets/img/produit_1.png"/>
        <img src="~@/assets/img/produit_2.png"/>
        <img src="~@/assets/img/produit_3.png"/>
        <img src="~@/assets/img/produit_4.png"/>
        <img src="~@/assets/img/produit_5.png"/>
        <img src="~@/assets/img/produit_6.png"/>
        <img src="~@/assets/img/produit_7.png"/>
        <img src="~@/assets/img/produit_8.png"/>
        <img src="~@/assets/img/produit_9.png"/>
        <img src="~@/assets/img/produit_10.png"/>
        <img src="~@/assets/img/produit_11.png"/>
        <img src="~@/assets/img/balloon_right.png"/>

    </div>
</template>

<!-- ================================================================================== -->

<script>

    export default {
        name:"products",
    }
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
    #productImages{
        display: flex;
        /* width: 500px; */
        /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
        /* display: grid; */
        /* align-items: center; */
        /* align-content: center; */
        /* justify-items: center; */
        grid-gap: 1em;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;

        img{
            height:170px;
        }
        @media screen and (max-width: 460px){
            img{
                height: 80px;
            }
        }
    }

</style>
